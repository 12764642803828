/**
 * Navigation component based on bootstrap logic
 * Taken from version 1
 */

// General import
import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

// Bootstap components
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

// translation library
import { useTranslation } from "react-i18next";

// additional elements
import "./NavigationBar.css";
import SocialMedia from "./SocialMediaBar";

// Own style element
//import styles from "./NavigationBar.module.css";
import navlogo from "../../assets/layout/logo-sm-32x32.png";

const NavigationBar = () => {
  // translation object
  const { t } = useTranslation();

  // helps to manage nav expansion
  const [expanded, setExpanded] = useState(false);
  const onCloseHandler = () => {
    setExpanded(expanded ? false : "expanded");
  };

  return (
    <Navbar expand="lg" expanded={expanded} className="navbar" id="start">
      <Navbar.Brand className="keyvisual">
        <img className="logo" src={navlogo} alt="DieStressmanagerin.com" />
        <Link to="home" className="headerlinks posizer">
          {t("navigation.logo")}
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle
        className="navtoggle"
        aria-controls="basic-navbar-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={onCloseHandler}
      />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Item className="linkitem">
            <Link
              className="headerlinks"
              to="home#goals"
              onClick={onCloseHandler}
            >
              {t("navigation.goals")}
            </Link>
          </Nav.Item>
          <Nav.Item className="linkitem">
            <Link
              className="headerlinks"
              to="home#services"
              onClick={onCloseHandler}
            >
              {t("navigation.service")}
            </Link>
          </Nav.Item>
          <Nav.Item className="linkitem">
            <Link
              className="headerlinks"
              to="home#test"
              onClick={onCloseHandler}
            >
              {t("navigation.testimonial")}
            </Link>
          </Nav.Item>
          <Nav.Item className="linkitem">
            <Link
              className="headerlinks"
              to="home#VideoSection"
              onClick={onCloseHandler}
            >
              {t("navigation.podcast")}
            </Link>
          </Nav.Item>
          <Nav.Item className="linkitem">
            <Link
              className="headerlinks"
              to="home#news"
              onClick={onCloseHandler}
            >
              {t("navigation.news")}
            </Link>
          </Nav.Item>
        </Nav>
        <SocialMedia cname="icons-pri" />
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
