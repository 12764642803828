/**
 * PageEnding is a helper Element to cover the fixed FooterBar!
 */

// standard import
import React, { Fragment } from "react";

const PageEnding = () => {
  return (
    <Fragment>
      <div className="container px-3">
        <div className="dsm-sectdev">&nbsp;</div>
      </div>
    </Fragment>
  );
};

export default PageEnding;
