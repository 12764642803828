/**
 * Page: LangButton
 */

// standard import
import React, { Fragment, useState } from "react";

// translation library
import i18n, { languageLabels } from "../../i18n";

// css
import "./LanguageButton.css";

const LanguageButton = () => {
  const [showLang, setShowLang] = useState(i18n.language);

  // Change Language Label & Language Setting
  const changeLanguageHandler = () => {
    //log.debug("[LanguageButton] [1] CURRENT LANGUAGE: " + showLang);

    let index = languageLabels.findIndex(
      (languageLabels) => languageLabels === showLang
    );

    if (index === -1 || index + 1 >= languageLabels.length) {
      index = 0;
    } else {
      index = index + 1;
    }

    // set new useState
    setShowLang(languageLabels[index]);

    // change general language setting
    i18n.changeLanguage(languageLabels[index]);

    // change button
    inversLanguage(languageLabels[index]);

   // log.debug("[LanguageButton] [2] CHANGE TO LANGUAGE: " + showLang);
  };

  // Check Language to shown on label
  const inversLanguage = (selectedLanguage) => {
    let index = languageLabels.findIndex(
      (languageLabels) => languageLabels === selectedLanguage
    );

    if (index >= languageLabels.length - 1) {
      index = 0;
    } else {
      index = index + 1;
    }

    // log.debug(
    //   "[LanguageButton] [3] CHANGE LABLE INVERSE TO: " + languageLabels[index]
    // );
    return languageLabels[index];
  };

  return (
    <Fragment>
      <button onClick={changeLanguageHandler} className="buttonLanguage">
        {inversLanguage(i18n.language)}
      </button>
    </Fragment>
  );
};

export default LanguageButton;
