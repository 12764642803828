/**
 * Config File for i18 Multilanguage-SetUp
 * https://react.i18next.com
 * It is important to know that the translation files are located in the "Public folder":
 * "../locales/{language}/translation.json"
 */

// Core Elements
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import detectBrowserLanguage from "detect-browser-language";

export const languageLabels = ["de", "en"];

// detect BrowserLanguage and delivers language to use (ISO 639-1)
// USE: detectBrowserLanguage() from external package 
const getInitialStartLangauge = () => {

  let browserLanguage =  detectBrowserLanguage();

  if (browserLanguage.length > 2){
    browserLanguage = browserLanguage.substring(0,2).trim();
    // log.debug("[i18n] REDUCED TO ISO-SCHEMA: " + browserLanguage);
  }

  let index = languageLabels.findIndex(
    (languageLabels) => languageLabels === browserLanguage
  );

  //log.debug ("[i18n] INDEX: " + index);

  if (index === -1) {
   // log.debug("[i18n] CHANGE INDEX");
    index = 0;
  }

  return languageLabels[index];;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    whitelist: languageLabels,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
  });


// Init Langauge
//log.debug("[i18n] DETECTED BROWSER LANGUAGE: " + detectBrowserLanguage());
i18n.changeLanguage(getInitialStartLangauge());

export default i18n;
