/**
 * SocialMediaBar
 */

// standard import
import React, { Fragment } from "react";

// Additonal libraries
import { useAccordionButton } from "react-bootstrap";

// icons used for social media
import { BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";

// additional CSS
import "./SocialMediaBar.css";

// integrated component
const CustomToggle = ({ children, linkElement, styleElement }) => {
  const decoratedOnClick = useAccordionButton(linkElement, () => { 
    //log.debug("[Link to ...] " + linkElement); 
    window.open(linkElement);
  }
  );
  return (
    <button type="button" onClick={decoratedOnClick} className={styleElement}>
      {children}
    </button>
  );
};

const SocialMediaBar = (props) => {
  //log.debug("[SocialMediaBar] Access component and Props: " + props.cname);

  const linkInstagram = "https://www.instagram.com/die_stress_managerin";
  const linkTwitter = "https://twitter.com/Stephan09451032";
  const linkLinkedIn =
    "https://de.linkedin.com/in/stephanie-schaffner-086a37128";

  return (
    <Fragment>
      <CustomToggle linkElement={linkInstagram} styleElement={props.cname}>
        <BsInstagram className={props.cname} />
      </CustomToggle>
      <CustomToggle linkElement={linkTwitter} styleElement={props.cname}>
        <BsTwitter className={props.cname} />
      </CustomToggle>
      <CustomToggle linkElement={linkLinkedIn} styleElement={props.cname}>
        <BsLinkedin className={props.cname} />
      </CustomToggle>
    </Fragment>
  );
};

export default SocialMediaBar;
