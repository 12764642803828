/**
 * CookieNotice
 * is used instead of "CookieConsent.js"
 */

// standard import
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

// translation library
import { useTranslation } from 'react-i18next';

// Additional Stylesheet
import "./CookieNoticeNew.css";

// Additonal libraries
// import log from "loglevel";

const CookieNotice = (props) => {

  // Translation object
  const { t } = useTranslation(); 

  // Text elements
  const cookieHeader = t("cookie.header") ;
  const cookieBody = t("cookie.body") ;
  const cookielinktxt = t("cookie.linktxt") ;
  const cookiebtntxt = t("cookie.button");

  return (
    <Fragment>
      <div className="cookieNoticePos">
        <Alert
          {...props}
          style={{
            backgroundColor: "var(--color-primary)",
            color: "var(--color-secondary)",
            borderRadius: 6,
          }}
        >
          <Alert.Heading>{cookieHeader}</Alert.Heading>
          <p>
            {cookieBody}
            <Link
              to="dataprotection"
              onClick={props.onClose}
              className="dsm-maillink-white"
            >
              {cookielinktxt}
            </Link>
          </p>
          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "-20px" }}
          >
            <Button
              onClick={props.onClose}
              style={{ backgroundColor: "var(--color-third)", color: "black" }}
            >
              {cookiebtntxt}
            </Button>
          </div>
        </Alert>
      </div>
    </Fragment>
  );
};

export default CookieNotice;
