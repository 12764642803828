/**
 * Main App File
 */

// Core imports
import React, { useState, Suspense, lazy, Fragment } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// Pages & Components
import LoadingSpinner from "./components/layout/LoadingSpinner";
import NavigationBar from "./components/layout/NavigationBar";
import PageEnding from "./components/layout/PageEnding";
import FooterBar from "./components/layout/FooterBar";
import CookieNotice from "./components/layout/CookieNoticeNew";

// Changed to optize the loading of the code
const Home = lazy(() => import("./pages/Home"));
const Imprint = lazy(() => import("./pages/Imprint"));
const Dataprotection = lazy(() => import("./pages/Dataprotection"));
const NotFound = lazy(() => import("./pages/NotFound"));

const App = () => {
  // log.setLevel(process.env.REACT_APP_LOGLEVEL);
  // log.info("[APP] NAME: " + appInfo.name);
  // log.info("[APP] VERSION: " + appInfo.version);
  // log.info("[APP] LANGUAGE SETTING: " + i18n.language.toUpperCase());
 
  // read version number to show in footer
  const package_json = require("../package.json");
  console.log(`Welcome to: ${package_json.name}`);
  console.log(`Website version: ${package_json.version}`);

  const [modalShow, setModalShow] = useState(true);
  const location = useLocation();

  return (
    <Fragment>
      <Suspense fallback={<LoadingSpinner />}>
        <CookieNotice
          show={modalShow}
          onClose={() => setModalShow(false)}
          variant="dark"
        />
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="imprint" element={<Imprint />} />
          <Route path="dataprotection" element={<Dataprotection />} />
          <Route path="*" element={<NotFound target={location.pathname}/>} />
        </Routes>
        <PageEnding />
        <FooterBar/>
      </Suspense>
    </Fragment>
  );
};

export default App;
