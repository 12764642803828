/**
 * FooterBar
 */

// standard import
import React, { Fragment } from "react";
import { HashLink as Link } from 'react-router-hash-link';

// components
import SocialMediaBar from "./SocialMediaBar";
import LanguageButton from "./LanguageButton";

// icons used for social media
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdCopyright } from "react-icons/md";

// translation library
import { useTranslation } from 'react-i18next';

// additional CSS
import "./FooterBar.css";

const FooterBar = (props) => {

  const { t } = useTranslation();

  const changeLanguage = (selectedLanguage) => {
    //log.debug("[FooterBar] SELECTED LANGUAGE: " + selectedLanguage);
    props.onChangeLanguage (selectedLanguage);
     }

  return (
    <Fragment>
      <div className="footer ">
        <div className="text-center footer-top-el">
          <SocialMediaBar cname="icons-sec" />
          <LanguageButton onChangeLang={changeLanguage}/>
            </div>
        <div className="text-center footer-bottom-el">
            <Link to="dataprotection" className="footerlinks">
              {t("footer.dataprotection")}
            </Link>
            <BsThreeDotsVertical />
            <Link to="imprint" className="footerlinks">
            {t("footer.imprint")}
            </Link>
            <BsThreeDotsVertical />
            <Link to="home#start" className="footerlinks">
              <MdCopyright />{t("footer.page_name")}
            </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default FooterBar;
